module.exports = {
  siteTitle: 'EcoPestilence - Ecological Solutions for Pest Management', // <title>
  manifestName: 'EcoPestilence',
  manifestShortName: 'EcoPestilence', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  heading: 'EcoPestilence',
  subHeading: 'Ecological Solutions for Pest Management',
  phoneNumber: '(855) 532-6737',
  siteDescription:
    'Eco Pestilence - Proudly Serving Brea, La Habra, Fullerton, and the greater Orange County area!',
  siteKeywords: 'Pest Control Ecological Eco',
  // social
  socialLinks: [
    {
      style: 'brands',
      icon: 'fa-facebook',
      name: 'Facebook',
      url: 'https://www.facebook.com/Eco-Pestilence-718259088208589/',
    },
    {
      style: 'brands',
      icon: 'fa-yelp',
      name: 'Yelp',
      url: 'https://www.yelp.com/biz/eco-pestilence-brea',
    },
  ],
};
